<template>
  <div class="view-contanier">
    <div class="opt">
      <div class="btn" @click="add">
        <svg-icon
          v-permissson="'add'"
          className="icon"
          iconClass="add" />新增
      </div>
    </div>
    <div class="data">
      <BaseTable
        v-if="height !== 0"
        :height="height"
        :tableData="tableData"
        :isPager="false"
        :loading="loading"
        @sortChange="sortChange"
      >
        <template slot="isActived" slot-scope="row">
          <div
            class="circle"
            :style="{
              'background-color': row.scope.isActived ? ' #35BA83' : '#FE453D',
            }"
          ></div>
          {{ row.scope.isActived ? "使用中" : "未使用" }}
        </template>
        <template slot="follow" slot-scope="row">
          <el-tag v-for="(item, $index) in row.scope.follow.split(',')" :key="$index">
            {{ item | followFilters }}
          </el-tag>
        </template>
        <template slot="opt" slot-scope="row">
          <div class="table-opt">
            <el-switch
              v-model="row.scope.isActived"
              v-permissson="'able'"
              active-color="#2395F3"
              inactive-color="#CDCDCD"
              :active-text="row.scope.isActived ? '启用' : '停用'"
              @change="
                (value) => {
                  changeStatus(value, row.scope);
                }
              "
            >
            </el-switch>
            <span
              v-permissson="'delete'"
              class="delete"
              @click="removeRow(row.scope)"
            >删除</span>
          </div>
        </template>
      </BaseTable>
    </div>
    <add-work-bill-dialog
      :dialogFormVisible="dialogFormVisible"
      @ok="ok"
      @cancel="cancel"
    ></add-work-bill-dialog>
  </div>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable')
import {
  getWorkOrderItemPageList,
  swichWorkOrderItem,
  deleteWorkOrderItem
} from '@/api/system'
import AddWorkBillDialog from './components/AddWorkBillDialog'
import { myMixins } from '@/mixins'
export default {
  name: 'WorkBill',
  components: { BaseTable, AddWorkBillDialog },
  filters: {
    followFilters(v) {
      const obj = {
        'Operation': '操作',
        'Customer': '客服',
        'Finance': '财务',
        'Sales': '销售'
      }
      return obj[v]
    }
  },
  mixins: [myMixins],
  data() {
    return {
      pagerConfig: {
        pageIndex: 1,
        pageSize: 1000
      },
      loading: false,
      tableData: {
        fileds: [
          {
            filed: 'basicName',
            label: '基础类目',
            width: '200px',
            fixed: 'left',
            isSort: true
          },
          {
            filed: 'basicCode',
            label: '基础类目代码',
            width: '120px',
            isSort: true
          },
          {
            filed: 'name',
            label: '子类目',
            width: '200px',
            isSort: true
          },
          {
            filed: 'code',
            label: '子类目代码',
            width: '120px',
            isSort: true
          },
          {
            filed: 'isActived',
            label: '状态',
            width: '100px',
            isCustom: true,
            isSort: true
          },
          {
            filed: 'classificationName',
            label: '类型',
            width: '100px',
            isSort: true
          },
          {
            filed: 'createdName',
            label: '创建人',
            width: '180px'
          },
          {
            filed: 'createdOn',
            label: '创建时间',
            width: '180px',
            formatter: (row) =>
              row.createdOn ? row.createdOn.slice(0, row.createdOn.length - 3) : '',
            isSort: true
          },
          {
            filed: 'follow',
            label: '可见组',
            width: '',
            isCustom: true
          },
          {
            filed: 'opt',
            label: '操作',
            width: '160px',
            isCustom: true
          }
        ],
        columns: [],
        total: 0
      },
      height: 0,
      dialogFormVisible: false,
      editData: {},
      sortable: {
        sortField: undefined,
        sortBy: undefined
      }
    }
  },
  computed: {},
  created() {
    this.getList(this.pagerConfig)
  },
  mounted() {
    this.height = document.querySelector('.data').offsetHeight - 43
  },
  methods: {
    async getList(params) {
      try {
        this.loading = true
        params = {
          ...params,
          queryParam: {}
        }
        const res = await getWorkOrderItemPageList(params)
        if (res.success) {
          this.loading = false
          this.tableData.total = res.data.total
          this.tableData.columns = res.data.list
        } else {
          this.$message.error(res.errorMessage)
          this.loading = false
        }
      } catch (error) {
        this.loading = false
        console.error(error)
      }
    },
    // handlePageChange(pager) {
    //   this.pagerConfig = {
    //     ...this.pagerConfig,
    //     ...pager
    //   }
    //   const params = {
    //     ...this.pagerConfig,
    //     ...pager
    //   }
    //   this.getList(params)
    // },
    sortChange({ prop, order }) {
      if (order === 'desc') {
        if (prop === 'isActived') {
          this.tableData.columns = this.tableData.columns.sort((a, b) => b[prop] - a[prop])
        } else {
          this.tableData.columns = this.tableData.columns.sort((a, b) => b[prop].localeCompare(a[prop]))
        }
      } else {
        if (prop === 'isActived') {
          this.tableData.columns = this.tableData.columns.sort((a, b) => a[prop] - b[prop])
        } else {
          this.tableData.columns = this.tableData.columns.sort((a, b) => a[prop].localeCompare(b[prop]))
        }
      }
    },
    removeRow(row) {
      deleteWorkOrderItem({
        id: row.id
      }).then((res) => {
        if (res.success) {
          this.$message.success('删除成功')
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    add() {
      this.dialogFormVisible = true
    },
    ok() {
          this.getList({
            pageIndex: 1,
            pageSize: 1000
          })
          this.pagerConfig = {
            pageIndex: 1,
            pageSize: 1000
          }
      this.dialogFormVisible = false
    },
    cancel() {
      this.dialogFormVisible = false
    },
    changeStatus(value, row) {
      const obj = {
        id: row.id
      }
      swichWorkOrderItem(obj).then((res) => {
        if (res.success) {
          this.$message.success('启用成功')
          this.getList({
            pageIndex: 1,
            pageSize: 1000
          })
          this.pagerConfig = {
            pageIndex: 1,
            pageSize: 1000
          }
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.view-contanier {
  width: 100%;
  padding: 0 10px;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  overflow: auto;
  .opt {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .btn {
    width: 80px;
    height: 30px;
    background: #2395f3;
    border-radius: 4px;
    opacity: 1;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
  }
  .icon {
    font-size: 20px;
    vertical-align: text-top;
  }
  .data {
    width: 100%;
    height: calc(100% - 50px);
    & .fTable {
      border-top: 1px solid #ebeef5;
      box-sizing: border-box;
    }
  }
  .circle {
    width: 6px;
    height: 6px;
    opacity: 1;
    display: inline-block;
    border-radius: 50%;
    margin-right: 6px;
  }
  .table-opt {
    display: flex;
    align-items: center;
    .delete {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      margin-left: 30px;
      cursor: pointer;
    }
  }
  .table-opt:deep(.el-button) {
    padding: 0;
  }
  .status {
    width: 28px;
    height: 19px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #2395f3;
    line-height: 20px;
    margin-left: 5px;
    margin-right: 31px;
  }
  .el-tag + .el-tag {
    margin-left: 10px;
  }
}
</style>
