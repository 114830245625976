var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "view-contanier" },
    [
      _c("div", { staticClass: "opt" }, [
        _c(
          "div",
          { staticClass: "btn", on: { click: _vm.add } },
          [
            _c("svg-icon", {
              directives: [
                {
                  name: "permissson",
                  rawName: "v-permissson",
                  value: "add",
                  expression: "'add'",
                },
              ],
              attrs: { className: "icon", iconClass: "add" },
            }),
            _vm._v("新增 "),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "data" },
        [
          _vm.height !== 0
            ? _c("BaseTable", {
                attrs: {
                  height: _vm.height,
                  tableData: _vm.tableData,
                  isPager: false,
                  loading: _vm.loading,
                },
                on: { sortChange: _vm.sortChange },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "isActived",
                      fn: function (row) {
                        return [
                          _c("div", {
                            staticClass: "circle",
                            style: {
                              "background-color": row.scope.isActived
                                ? " #35BA83"
                                : "#FE453D",
                            },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(
                                row.scope.isActived ? "使用中" : "未使用"
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "follow",
                      fn: function (row) {
                        return _vm._l(
                          row.scope.follow.split(","),
                          function (item, $index) {
                            return _c("el-tag", { key: $index }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("followFilters")(item)) +
                                  " "
                              ),
                            ])
                          }
                        )
                      },
                    },
                    {
                      key: "opt",
                      fn: function (row) {
                        return [
                          _c(
                            "div",
                            { staticClass: "table-opt" },
                            [
                              _c("el-switch", {
                                directives: [
                                  {
                                    name: "permissson",
                                    rawName: "v-permissson",
                                    value: "able",
                                    expression: "'able'",
                                  },
                                ],
                                attrs: {
                                  "active-color": "#2395F3",
                                  "inactive-color": "#CDCDCD",
                                  "active-text": row.scope.isActived
                                    ? "启用"
                                    : "停用",
                                },
                                on: {
                                  change: (value) => {
                                    _vm.changeStatus(value, row.scope)
                                  },
                                },
                                model: {
                                  value: row.scope.isActived,
                                  callback: function ($$v) {
                                    _vm.$set(row.scope, "isActived", $$v)
                                  },
                                  expression: "row.scope.isActived",
                                },
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "permissson",
                                      rawName: "v-permissson",
                                      value: "delete",
                                      expression: "'delete'",
                                    },
                                  ],
                                  staticClass: "delete",
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeRow(row.scope)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3611605748
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("add-work-bill-dialog", {
        attrs: { dialogFormVisible: _vm.dialogFormVisible },
        on: { ok: _vm.ok, cancel: _vm.cancel },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }