var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "新增",
        visible: _vm.dialogFormVisible,
        width: "800px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "append-to-body": "",
        "show-close": true,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("base-tab", {
            attrs: {
              tabItems: _vm.tabItems,
              propWidth: 320,
              active: _vm.isActived,
            },
            on: { tabChange: _vm.tabChange },
          }),
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "form",
              attrs: { model: _vm.form, rules: _vm.rules },
            },
            [
              _vm.isActived === 0
                ? _c(
                    "div",
                    { staticClass: "form-item" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                        _vm._v("基础类目代码"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "code" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入基础类目代码" },
                            model: {
                              value: _vm.form.code,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "code", $$v)
                              },
                              expression: "form.code",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isActived === 0
                ? _c(
                    "div",
                    { staticClass: "form-item" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                        _vm._v("基础类目名称"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入基础类目名称" },
                            model: {
                              value: _vm.form.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isActived === 1
                ? _c(
                    "div",
                    { staticClass: "form-item" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                        _vm._v("基础类目"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "workOrderTypeid" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择费用类型" },
                              model: {
                                value: _vm.form.workOrderTypeid,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "workOrderTypeid", $$v)
                                },
                                expression: "form.workOrderTypeid",
                              },
                            },
                            _vm._l(_vm.workOrderOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isActived === 1
                ? _c(
                    "div",
                    { staticClass: "form-item" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                        _vm._v("子类目名称"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入子类目名称" },
                            model: {
                              value: _vm.form.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isActived === 1
                ? _c(
                    "div",
                    { staticClass: "form-item" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                        _vm._v("子类目代码"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "code" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入子类目代码" },
                            model: {
                              value: _vm.form.code,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "code", $$v)
                              },
                              expression: "form.code",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isActived === 1
                ? _c(
                    "div",
                    { staticClass: "form-item" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                        _vm._v("可见组"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "follow" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                multiple: "",
                                placeholder: "请选择可见组",
                              },
                              model: {
                                value: _vm.form.follow,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "follow", $$v)
                                },
                                expression: "form.follow",
                              },
                            },
                            _vm._l(
                              _vm.config.workBillCustomerOptions,
                              function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isActived === 1
                ? _c(
                    "div",
                    { staticClass: "form-item" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                        _vm._v("首次执行人"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "firstHold" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择首次执行人" },
                              model: {
                                value: _vm.form.firstHold,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "firstHold", $$v)
                                },
                                expression: "form.firstHold",
                              },
                            },
                            _vm._l(_vm.firstHoldOption, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isActived === 1
                ? _c(
                    "div",
                    { staticClass: "form-item" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                        _vm._v("类型"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "classification" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择类型" },
                              model: {
                                value: _vm.form.classification,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "classification", $$v)
                                },
                                expression: "form.classification",
                              },
                            },
                            _vm._l(
                              _vm.config.classificationHoldOption,
                              function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "div",
            {
              staticClass: "ok flex_c_c",
              on: {
                click: function ($event) {
                  return _vm.ok("form")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }