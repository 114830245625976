<template>
  <el-dialog
    v-drag
    title="新增"
    :visible.sync="dialogFormVisible"
    width="800px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
    :show-close="true"
    :before-close="cancel"
  >
    <div class="content">
      <base-tab :tabItems="tabItems" :propWidth="320" :active="isActived" @tabChange="tabChange"></base-tab>
      <el-form ref="form" class="form" :model="form" :rules="rules">
        <div v-if="isActived === 0" class="form-item">
          <label class="lable"><span class="red">* </span>基础类目代码</label>
          <el-form-item prop="code">
            <el-input
              v-model="form.code"
              placeholder="请输入基础类目代码"
            ></el-input>
          </el-form-item>
        </div>
        <div v-if="isActived === 0" class="form-item">
          <label class="lable"><span class="red">* </span>基础类目名称</label>
          <el-form-item prop="name">
            <el-input
              v-model="form.name"
              placeholder="请输入基础类目名称"
            ></el-input>
          </el-form-item>
        </div>
        <div v-if="isActived === 1" class="form-item">
          <label class="lable"><span class="red">* </span>基础类目</label>
          <el-form-item prop="workOrderTypeid">
            <el-select
              v-model="form.workOrderTypeid"
              style="width: 100%"
              placeholder="请选择费用类型"
            >
              <el-option
                v-for="item in workOrderOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div v-if="isActived === 1" class="form-item">
          <label class="lable"><span class="red">* </span>子类目名称</label>
          <el-form-item prop="name">
            <el-input
              v-model="form.name"
              placeholder="请输入子类目名称"
            ></el-input>
          </el-form-item>
        </div>
        <div v-if="isActived === 1" class="form-item">
          <label class="lable"><span class="red">* </span>子类目代码</label>
          <el-form-item prop="code">
            <el-input
              v-model="form.code"
              placeholder="请输入子类目代码"
            ></el-input>
          </el-form-item>
        </div>
        <div v-if="isActived === 1" class="form-item">
          <label class="lable"><span class="red">* </span>可见组</label>
          <el-form-item prop="follow">
            <el-select
              v-model="form.follow"
              multiple
              style="width: 100%;"
              placeholder="请选择可见组"
            >
              <el-option
                v-for="item in config.workBillCustomerOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div v-if="isActived === 1" class="form-item">
          <label class="lable"><span class="red">* </span>首次执行人</label>
          <el-form-item prop="firstHold">
            <el-select
              v-model="form.firstHold"
              style="width: 100%;"
              placeholder="请选择首次执行人"
            >
              <el-option
                v-for="item in firstHoldOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div v-if="isActived === 1" class="form-item">
          <label class="lable"><span class="red">* </span>类型</label>
          <el-form-item prop="classification">
            <el-select
              v-model="form.classification"
              style="width: 100%;"
              placeholder="请选择类型"
            >
              <el-option
                v-for="item in config.classificationHoldOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>
<script>
const BaseTab = () => import('@/components/tab/BaseTab')
import {
  insertWorkOrderType,
  getWorkOrderTypeList,
  insertWorkOrderItem
} from '@/api/system'
import config from '@/utils/config'
export default {
  components: { BaseTab },
  props: {
    dialogFormVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      workOrderOptions: [],
      tabItems: [
        {
          id: 0,
          name: '基础类目'
        },
        {
          id: 1,
          name: '子类目'
        }
      ],
      form: {
        code: '',
        name: '',
        workOrderTypeid: '',
        follow: [],
        firstHold: '',
        classification: 100
      },
      rules: {
        code: [
          { required: true, message: '请输入类目代码', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入类目名称', trigger: 'blur' }
        ],
        workOrderTypeid: [
          { required: true, message: '请选择费用类型', trigger: 'change' }
        ],
        classification: [
          { required: true, message: '请选择类型', trigger: 'change' }
        ],
        follow: [
          {
            required: true,
            message: '请输入选择子类目流向',
            trigger: 'change'
          }
        ],
        firstHold: [
          {
            required: true,
            message: '请输入选择首次执行人',
            trigger: 'change'
          }
        ]
      },
      isActived: 0,
      commData: [],
      dialogVisible2: false,
      config
    }
  },
  computed: {
    firstHoldOption: function() {
      return this.config.workBillCustomerOptions.filter(a => this.form.follow.indexOf(a.value) > -1)
    }
},
  watch: {
    dialogFormVisible(val) {
      if (val) {
        this.getList()
      }
    }
  },
  created() {
  },
  methods: {
    tabChange(item) {
      this.isActived = item.id
    },
    cancel() {
      this.isActived = 0
      this.$refs['form'].resetFields()
      this.form.follow = []
      this.$emit('cancel')
    },
    add() {
      this.dialogVisible2 = true
    },
    ok(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.isActived === 0) {
            insertWorkOrderType({
              ...this.form,
              isActived: true
            }).then((res) => {
              if (res.success) {
                this.$message.success('新增成功')
                this.$emit('ok')
              } else {
                this.$message.success(res.errorMessage)
              }
            })
          } else {
            insertWorkOrderItem({
              ...this.form,
              isActived: true
            }).then((res) => {
              if (res.success) {
                this.$message.success('新增成功')
                this.$emit('ok')
              } else {
                this.$message.success(res.errorMessage)
              }
            })
          }
        }
      })
    },
    async getList() {
      try {
        const res = await getWorkOrderTypeList()
        if (res.success) {
          const arr = []
          res.data &&
            res.data.map((item) => {
              arr.push({
                value: item.id,
                label: item.name
              })
            })
          this.workOrderOptions = arr
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    addClass() {
      this.dialogVisible2 = true
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  position: relative;
  width: 100%;
  .tab-content {
    position: absolute;
    top: -80px;
    left: 50%;
    transform: translateX(-50%);
  }
  .form {
    display: flex;
    width: calc(100% - 80px);
    height: auto;
    flex-wrap: wrap;
    margin: 40px auto 0;
  }
  .form-item {
    width: calc(50% - 20px);
    position: relative;
    .lable {
      width: 100%;
      height: 19px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin-bottom: 5px;
    }
    .red {
      color: rgba(255, 116, 68, 1);
    }
    &:nth-child(2n) {
      margin-left: 40px;
    }
    .addClass {
      position: absolute;
      width: 84px;
      height: 40px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #2395f3;
      line-height: 40px;
      margin-left: 10px;
      cursor: pointer;
    }
  }
}
.footers {
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
/deep/.el-form--label-top .el-form-item__label {
  padding: 0;
}
</style>
